import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h1',{staticClass:"headline pb-4 mx-lg-auto"},[_vm._v("Reports")]),_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.availableReports,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.exportReport}}),_c(VDialog,{attrs:{"width":"480","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showExportDialog),callback:function ($$v) {_vm.showExportDialog=$$v},expression:"showExportDialog"}},[[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.dialogTitle))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showExportDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.showDialogLoader)?_c(VCard,{attrs:{"height":"200","flat":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":50,"color":"primary"}})],1)],1)],1):_vm._e(),_c(_vm.currentDialogComponent,_vm._b({tag:"component",on:{"hook:mounted":_vm.currentDialogComponentCreated}},'component',_vm.currentDialogComponentProperties,false))],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }