<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">Reports</h1>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="availableReports"
        @click:row="exportReport"
        disable-pagination
        hide-default-footer
      >
      </v-data-table>
      <v-dialog width="480" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showExportDialog">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="showExportDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card height="200" flat v-if="showDialogLoader">
              <v-layout align-center justify-center column fill-height>
                <v-flex row align-center>
                  <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>
                </v-flex>
              </v-layout>
            </v-card>

            <component
              :is="currentDialogComponent"
              v-bind="currentDialogComponentProperties"
              @hook:mounted="currentDialogComponentCreated"
            ></component>
          </v-card>
        </template>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<style>
.v-data-table-row {
  cursor: pointer;
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsView',
  methods: {
    exportReport(item) {
      this.showExportDialog = true;
      if (this.dialogTitle != item.name) {
        this.dialogTitle = item.name;
        this.currentDialogItem = item;
        this.showDialogLoader = true;
        this.currentDialogComponent = item.component;
      }
    },
    currentDialogComponentCreated() {
      this.showDialogLoader = false;
    }
  },
  computed: {
    ...mapGetters('report', ['availableReports']),
    currentDialogComponentProperties: function () {
      if (this.currentDialogItem.properties) {
        return this.currentDialogItem.properties;
      }
      return {};
    }
  },
  data: () => ({
    showExportDialog: false,
    dialogTitle: 'Dialog title',
    currentDialogItem: {},
    currentDialogComponent: null,
    showDialogLoader: false,
    breadcrumbs: [
      {
        text: 'Dashboard',
        disabled: false,
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Reports',
        disabled: false,
        exact: true,
        to: 'reports'
      }
    ],
    headers: [{ text: 'Title', value: 'name' }]
  })
};
</script>
